import React from 'react';
import '../styles/Resume.css';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHtml5, faCss3Alt, faJsSquare, faReact, faNodeJs, faPython,
    faJava, faAws, faBootstrap, faJira
} from '@fortawesome/free-brands-svg-icons';
import {
    faDatabase, faPuzzlePiece, faUsers, faCameraRetro, faHashtag, faPeopleGroup
} from '@fortawesome/free-solid-svg-icons';

const Skill = ({ icon, label }) => (
    <motion.div className="skill"
        whileHover={{ scale: 1.1, color: "#1E90FF" }}
        transition={{ duration: 0.2 }}
    >
        <FontAwesomeIcon icon={icon} className="logo" />
        <p>{label}</p>
    </motion.div>
);

const ImageSkill = ({ src, hoverSrc, label }) => {
    const [isHovered, setHovered] = React.useState(false);

    return (
        <motion.div className="skill"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <img src={process.env.PUBLIC_URL + (isHovered ? hoverSrc : src)} alt={label} className="logo" />
            <p>{label}</p>
        </motion.div>
    );
};

function Resume() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>
            <div className="resume">
                <section className="resume-header">
                    <h1>My Resume</h1>
                    <a href="/files/ZachBergerResume.pdf" download className="resume-download">
                        Download My Resume
                    </a>
                </section>
                <h3>Skills/Proficiencies:</h3>
                <div className="skills-grid">
                    <Skill icon={faHtml5} label="HTML" />
                    <Skill icon={faCss3Alt} label="CSS" />
                    <Skill icon={faJsSquare} label="JavaScript" />
                    <Skill icon={faDatabase} label="SQL" />
                    <Skill icon={faReact} label="React" />
                    <Skill icon={faJsSquare} label="JQuery" />
                    <Skill icon={faNodeJs} label="Node" />
                    <Skill icon={faJsSquare} label="Express.js" />
                    <Skill icon={faBootstrap} label="Bootstrap" />
                    <Skill icon={faAws} label="AWS Skill Builder" />
                    <Skill icon={faJira} label="Apache Maven" />
                    <Skill icon={faPython} label="Python" />
                    <Skill icon={faJava} label="Java" />
                    <Skill icon={faPuzzlePiece} label="Problem-solving" />
                    <Skill icon={faUsers} label="Teamwork" />
                    <Skill icon={faHashtag} label="Social Media and Digital Marketing" />
                    <Skill icon={faCameraRetro} label="Photography" />
                    <Skill icon={faPeopleGroup} label="Management" />
                    <ImageSkill src="/images/pslogo.png" hoverSrc="/images/pslogo_blue.png" label="Photoshop" />
                    <ImageSkill src="/images/lrlogo.png" hoverSrc="/images/lrlogo_blue.png" label="Lightroom" />
                </div>
            </div>
        </motion.div>
    );
}

export default Resume;
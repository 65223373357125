import React from 'react';
import '../styles/About.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Section({ title, content, image, reverse }) {
  const { inView, ref } = useInView({
    threshold: 0.1,
  });
  const animation = useAnimation();

  // State to track window width
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  // Handle window resize
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (inView) {
    animation.start({
      opacity: 1,
      x: 0,
      transition: { duration: 1 },
    });
  }

  // Apply reverse only if window width is greater than 768px
  const applyReverse = reverse && windowWidth > 768;

  return (
    <div ref={ref} className={`about-section${applyReverse ? ' reverse' : ''}`}>
      <motion.div animate={animation} initial={{ opacity: 0, x: applyReverse ? 100 : -100 }}>
        <div className="title-background">
        <h2 style={{ fontFamily: 'Bricolage Grotesque, sans-serif' }}>{title}</h2>
        </div>
        <p>{content}</p>
      </motion.div>
      <motion.img
        src={image}
        alt={title}
        animate={animation}
        initial={{ opacity: 0, x: applyReverse ? -100 : 100 }}
        transition={{ delay: 0.5 }}
      />
    </div>
  );
}

function About() {
    const sections = [
      {
        title: 'Who am I?',
        content: 'Existential question, but I\'ll keep it basic. I\'m Zach Berger, a full stack web developer from New York, NY. Passionate about technology and driven by curiosity, I pursued web development to understand the underlying technology behind websites and build robust web applications.',
        image: process.env.PUBLIC_URL + '/images/image1.jpg', // replace with your image path
      },
      {
        title: 'Skills & Education',
        content: 'Proficient in HTML, CSS, JavaScript, SQL, React, and more, I possess a solid foundation in both front-end and back-end development. During my time at the Columbia Coding Boot Camp, I collaborated on projects like "Popcorn Portal," a movie discovery app, and "JamVault," a concert memory social media network. Check out my portfolio to see some of my work!',
        image: process.env.PUBLIC_URL + '/images/image2.jpg', // replace with your image path
        reverse: true,
      },
      {
        title: 'Creativity & Marketing',
        content: 'As a creative thinker and quick learner, I\'m adept at problem-solving and working both independently and in teams. With a background in social media and digital marketing, I bring a unique perspective to my work. Additionally, my skills in photography, videography, photo/video editing, Photoshop, and Lightroom enhance my creativity in web design.',
        image: process.env.PUBLIC_URL + '/images/image3.jpg', // replace with your image path
      },
      {
        title: 'Approach to Challenges',
        content: 'I approach challenges by understanding the problem, breaking it down into manageable parts, exploring multiple solutions, and seeking help when needed. With a thirst for knowledge and a passion for continuous learning, I stay up-to-date with industry trends and best practices.',
        image: process.env.PUBLIC_URL + '/images/image4.jpg', // replace with your image path
        reverse: true,
      },
      {
        title: 'Future Endeavors',
        content: 'I\'m excited to embark on this new career path as a full stack web developer, leveraging my skills and experiences to create innovative web applications. Let\'s build something amazing together!',
        image: process.env.PUBLIC_URL + '/images/image5.jpg', // replace with your image path
      },
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}>
          <div className="about-container">
            {sections.map((section, index) => (
              <Section 
                  key={index}
                  title={section.title}
                  content={section.content}
                  image={section.image}
                  reverse={index % 2 !== 0}
              />
            ))}
          </div>
        </motion.div>
    );
}
    
export default About;